import React from "react";
import TitlePage from "../../components/TitlePage/TitlePage";
import Image from "../../images/2443aad60db12636446f 1.png";
import { useFETCH } from "../../Tools/APIs";
import LoadingPackageCard from "../../components/Cards/LoadingPackageCard";
import PackageCard from "./PackageCard";

export const FEATURES = [
  {
    key: "CHAT",
    name: "الدردشة",
  },
  {
    key: "PROFILE_VISITORS",
    name: "رؤية من زار بروفايلي",
  },
  {
    key: "CARES",
    name: "القدرة على اهتمام بشخص ما",
  },
  {
    key: "CHAT_NATIONALTIES",
    name: "القدرة على تحديد الجنسيات التي تراسلني",
  },
  {
    key: "CHAT_COUUNTRIES",
    name: "القدرة على تحديد البلدان التي تراسلني",
  },
  {
    key: "MANAGE_NOTIFICATIONS",
    name: "التحكم بالاشعارات التي تصلني",
  },
];

export default function Packages() {
  const { data, isLoading } = useFETCH(`v1/packages`);
  return (
    <div className="mx-auto w-[90%] pb-8">
      <TitlePage title={"Packages"} />
      <div className="grid w-full justify-center grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {isLoading ? (
          <>
            <LoadingPackageCard />
            <LoadingPackageCard />
            <LoadingPackageCard />
          </>
        ) : (
          data?.data.data.map((pack) => <PackageCard pack={pack} />)
        )}
      </div>
    </div>
  );
}
