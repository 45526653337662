import { useEffect, useState } from "react";
import OTPInput from "react-otp-input";
import { useContextHook } from "../../../Context/ContextOPen";
import { usePOST } from "../../../Tools/APIs";
import { Col, Container, Row } from "../../../Tools/Grid-system";
import { ButtonMain, Cards } from "../../../components";
import { useNext } from "../SignUp/Page2";
import Loading from "../../../Tools/Loading";
import { content } from "../../../Context/translate";
import { useNavigate } from "react-router-dom";
function VerificationCode() {
  const navigate = useNavigate();
  const [code, setCode] = useState();
  const { setListError, setMessageSuccess } = useContextHook();

  const { handleSubmit, formData, loading, successfulPost, setFormData } =
    usePOST({
      verification_code: "",
    });
  const { error } = useNext(formData);

  useEffect(() => {
    setFormData({
      verification_code: code,
    });
  }, [code]);

  useEffect(() => {
    if (successfulPost) {
      setMessageSuccess([content.PasswordUpdate]);
      navigate("/auth/set-password");
    }
  }, [successfulPost]);

  return (
    <Container>
      <Row>
        <Col md={8} className="mx-auto">
          <Cards isLoading={loading} className="mt-10">
            <Col md={10} className="mx-auto">
              <div className="flex justify-center flex-col items-center h-full  text-center w-2/3 mx-auto ">
                <div>
                  <p className="text-Secondary">
                    {content.WeHaveSentTheVerificationCodeToYourEmail}
                  </p>
                  <p className="font-semibold">
                    {sessionStorage.getItem("email") &&
                      sessionStorage.getItem("email")}
                  </p>
                </div>
                <div className="flex justify-center items-start gap-3 my-6">
                  <OTPInput
                    value={code}
                    onChange={setCode}
                    numInputs={4}
                    inputStyle="!w-10 border border-black rounded-md h-12 w-12 mx-2 px-3 out !outline-[#2684FF]"
                    renderSeparator={<span></span>}
                    renderInput={(props) => <input {...props} />}
                  />
                </div>
              </div>
              <ResetCode successfulPost={successfulPost} />
              <div className="w-fit mx-auto">
                <ButtonMain
                  onClick={() => {
                    if (error.length > 0) {
                      return setListError([...error]);
                    }
                    handleSubmit("v1/check/verification-code", "", false, true);
                  }}
                  title={content.Check}
                />
              </div>
            </Col>
          </Cards>
        </Col>
      </Row>
    </Container>
  );
}
export default VerificationCode;

const ResetCode = () => {
  const { handleSubmit, successfulPost, setFormData, loading } = usePOST({
    email: sessionStorage.getItem("email"),
  });
  const [remainingTime, setRemainingTime] = useState(60);
  useEffect(() => {
    const timer = setTimeout(() => {
      setRemainingTime((prevTime) => prevTime - 1);
    }, 1000);
    if (remainingTime === 0) {
      clearTimeout(timer);
    }
    return () => clearTimeout(timer);
  }, [remainingTime]);
  useEffect(() => {
    if (successfulPost) {
      setRemainingTime(60);
    }
  }, [successfulPost]);
  useEffect(() => {
    setFormData({
      email: sessionStorage.getItem("email"),
    });
  }, [sessionStorage.getItem("email")]);
  return (
    <div className="text-center max-sm:text-sm">
      {loading ? <Loading /> : ""}
      {remainingTime ? (
        <time>{"00:" + remainingTime} </time>
      ) : (
        <div
          className="cursor-pointer my-2"
          onClick={() =>
            handleSubmit("v1/send/verification-code", "", "", true)
          }
        >
          {content.ResendTheCode}
        </div>
      )}
    </div>
  );
};
