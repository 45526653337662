import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Cards } from "../../../components";
import { useContextHook } from "../../../Context/ContextOPen";
import { content } from "../../../Context/translate";
import female from "../../../images/female.svg";
import male from "../../../images/male.svg";
import { Col, Container, Row } from "../../../Tools/Grid-system";

function Page1() {
  const [check, setCheck] = useState(false);
  const { setListError } = useContextHook();
  const navigate = useNavigate();
  const nextPage = (e) => {
    if (check) {
      const fcmToken = sessionStorage.getItem("fcm_token");
      sessionStorage.clear();
      if (fcmToken !== null) {
        sessionStorage.setItem("fcm_token", fcmToken);
      }
      sessionStorage.setItem("gender", e);
      return navigate("page-2");
    } else {
      sessionStorage.removeItem("gender");
      return setListError([content.Oath]);
    }
  };
  return (
    <Container className=" mb-12 mt-12">
      <Row>
        <Col lg={7} md={9} sm={11} className="mx-auto">
          <Col lg={9} md={10} sm={12} className="mx-auto">
            <h2 className="text-xl text-center font-bold my-4 text-black max-md:my-2 max-md:text-lg max-md:text-center  ">
              {content.RegistrationSection}
            </h2>
            <p className="text-base text-start font-semibold text-gray-600 mt-6 max-md:text-sm max-md:text-center">
              {content.swear}
            </p>
            <div className="my-5 flex items-center justify-start border-Secondary bg-gray-100 border rounded-lg mt-12  p-3">
              <input
                type="checkbox"
                name="checkbox"
                onChange={() => setCheck(!check)}
                className="mx-3 w-5 h-5 "
              />
              <span className="text-Main text-base font-bold ">
                {content.IHaveTaken}
              </span>
            </div>

            <Row
              justify="center "
              className="space-x-5 max-md:space-y-3  max-md:space-x-0 !mt-6  "
            >
              <Col sm={8} md={4} className="mx-5">
                <Cards
                  onClick={() => nextPage("FEMALE")}
                  className="cursor-pointer  md:hover:scale-105 md:transition-all max-md:w-[80%] max-md:mx-auto bg-Forth "
                >
                  <div className="w-full h-full flex justify-center">
                    <img src={female} alt="" className="w-24 h-24" />
                  </div>

                  <p className="text-center text-xl font-bold mt-4 ">
                    {content.RegistrationAsAwife}
                  </p>
                </Cards>
              </Col>
              <Col sm={8} md={4} className="">
                <Cards
                  onClick={() => nextPage("MALE")}
                  className="cursor-pointer  hover:scale-105 transition-all max-md:w-[80%] max-md:mx-auto bg-Fifth"
                >
                  <div className="w-full h-full flex justify-center">
                    <img src={male} alt="" className="w-24 h-24 " />
                  </div>
                  <p className="text-center text-xl font-bold mt-4 ">
                    {content.RegisterAsaSpouse}
                  </p>
                </Cards>
              </Col>
            </Row>
          </Col>
        </Col>
      </Row>
    </Container>
  );
}
export default Page1;
