import { Link } from "react-router-dom";
import { ageVoid } from "../../Context/functions";
import { avatars } from "../../Context/lists";
import { content } from "../../Context/translate";
import { fileUrl, useFETCH } from "../../Tools/APIs";
import { Col } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading";
import Pagination from "../../Tools/Pagination";
import { Cards, ImageUser } from "../../components";
import NotFoundImages from "./NotFoundImages";

const MembersImages = () => {
  const { data, isLoading } = useFETCH(
    "v1/users/profile/picture/privilege/users?for_me=1"
  );

  if (isLoading) return <Loading />;

  return (
    <div>
      {data?.data?.data?.total !== 0 && (
        <Col md={12} className="mx-auto">
          <Pagination
            pageCount={Math.ceil(
              data?.data?.data?.total / data?.data?.data?.per_page
            )}
          >
            <Cards className="space-y-3">
              <div className="flex items-center justify-between text-Secondary font-bold text-xl mb-4 px-5">
                <p>{content.MemberName}</p>
                <p>{content.Date}</p>
              </div>
              {data?.data?.data?.data?.map((e) => (
                <Link to={`/view-profile/${e?.login_details?.id}`}>
                  <Card
                    key={e.login_details.id}
                    image={
                      e.images.length > 0
                        ? fileUrl + e.images[e.images.length - 1].image
                        : e?.avatar_id
                        ? avatars.filter(
                            (element) => element.id === e.avatar_id
                          )[0]?.img
                        : ""
                    }
                    name={e.secret_data?.name}
                    status={e.social_status?.social_status?.name}
                    age={e.social_status?.age}
                    id={e?.login_details?.id}
                    from={e.nationality_residence?.city?.name}
                    time={e.pivot_created_at}
                  />
                </Link>
              ))}
            </Cards>
          </Pagination>
        </Col>
      )}
      {!data?.data?.data?.total && !isLoading && (
        <NotFoundImages title={content.noMemberYouSeePhoto} />
      )}
    </div>
  );
};

export default MembersImages;

const Card = ({ image, name, status, age, from, time, id, onClick }) => {
  return (
    <div
      className="flex items-center px-4 flex-wrap cursor-pointer"
      onClick={onClick}
    >
      <div className="flex gap-5 items-center w-[70%] max-md:w-full ">
        <div>
          <ImageUser id={id} image={image} className="" />
        </div>
        <div>
          <p>
            <span className="font-bold text-Secondary max-md:text-sm ">
              {name}
            </span>{" "}
            <br />
            <span className="font-semibold text-Main max-md:text-sm ">
              {status} - {ageVoid(age)} - {from}
            </span>{" "}
          </p>
        </div>
      </div>
      <div className="text-end w-full text-Secondary font-bold max-md:text-sm">
        {time}
      </div>
    </div>
  );
};
