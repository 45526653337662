import React, { useEffect } from "react";
import { Checked } from ".";
import { useFETCH, usePOST } from "../../Tools/APIs";
import { Col } from "../../Tools/Grid-system";
import { ButtonMain, Cards } from "../../components";
import { content } from "../../Context/translate";
import Loading from "../../Tools/Loading";

const Notifications = () => {
  // const { data, isLoading } = useFetch("v1/settings");
  const { data, isLoading } = useFETCH("v1/settings");
  const { handleSubmit, setFormData, loading, formData } = usePOST({});
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit("v1/settings/notifications/update?_method=PUT", "", "", true);
  };
  useEffect(() => {
    setFormData({
      mail_notify: data?.data?.data?.mail_notify,
      care_notify: data?.data?.data?.care_notify,
      ignore_notify: data?.data?.data?.ignore_notify,
      undo_ignore_notify: data?.data?.data?.undo_ignore_notify,
      new_messages_notify: data?.data?.data?.new_messages_notify,
      image_access_notify: data?.data?.data?.image_access_notify,
    });
  }, [data?.data?.data]);
  const list = [
    {
      title: content.MailNotify,
      value: "mail_notify",
    },
    {
      title: content.CareNotify,
      value: "care_notify",
    },
    {
      title: content.IgnoreNotify,
      value: "ignore_notify",
    },
    {
      title: content.UndoIgnoreNotify,
      value: "undo_ignore_notify",
    },
    {
      title: content.NewMessagesNotify,
      value: "new_messages_notify",
    },

    {
      title: content.ImageAccessNotify,
      value: "image_access_notify",
    },
  ];

  return (
    <div>
      {loading && <Loading />}
      <Col md={12} className="mx-auto pt-5">
        <Cards>
          {list.map((e, i) => (
            <Checked
              key={i}
              active={formData?.[e.value]}
              onClick={() => {
                setFormData((prev) => ({
                  ...prev,
                  [e.value]: formData?.[e.value] ? 0 : 1,
                }));
              }}
              title={e.title}
            />
          ))}
          <div className="w-fit mr-auto">
            <ButtonMain
              title={content.Save}
              className="!px-14 mt-10"
              onClick={handleSubmitMain}
            />
          </div>
        </Cards>
      </Col>
    </div>
  );
};

export default Notifications;
