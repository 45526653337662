import { Elements } from "@stripe/react-stripe-js";
import React from "react";
import { useFilter } from "../../Tools/APIs";
import ConfirmPayment from "./ConfirmPayment";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe("pk_test_UQedOw5S3NLLJKB2LWI8TMxg");

export default function PaymentWrapper() {
  const { searchParams } = useFilter();
  const client_secret = searchParams.get("client_secret");
  return (
    <Elements options={{ clientSecret: client_secret }} stripe={stripePromise}>
      <ConfirmPayment />
    </Elements>
  );
}
