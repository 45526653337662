import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  FacebookIcon,
  TwitterIcon,
  TelegramIcon,
  TelegramShareButton,
} from "react-share";
import { content } from "../../Context/translate";

export default function ShareProfile({ setShow, url }) {
  return (
    <div>
      <div
        onClick={() => setShow(false)}
        className="fixed w-full h-full top-0 left-0 bg-black/20 z-[61] "
      ></div>
      <div className="w-fit fixed top-[55%] left-1/2 -translate-x-1/2 -translate-y-1/2 z-[62] bg-white rounded-xl">
        <div className=" bg-white space-y-8 p-4 chat overflow-y-scroll rounded-xl">
          <p>{content.share}</p>
          <div className="flex justify-center gap-8">
            <FacebookShareButton url={url}>
              <FacebookIcon size={36} round />
            </FacebookShareButton>
            <WhatsappShareButton url={url}>
              <WhatsappIcon size={36} round />
            </WhatsappShareButton>
            <TwitterShareButton url={url}>
              <TwitterIcon size={36} round />
            </TwitterShareButton>
            <TelegramShareButton url={url}>
              <TelegramIcon size={36} round />
            </TelegramShareButton>
          </div>
        </div>
      </div>
    </div>
  );
}
