import { content } from "../../Context/translate";
import { NavFilterSearch, TitlePage } from "../../components";

const NavImages = () => {
  return (
    <div className="pb-5 my-6 ">
      <TitlePage title="UsersImages" />
      <NavFilterSearch
        listNav={[
          // { name: content.AllowedToSeeMyPicture, link: "/user-images/" },
          // { name: content.MembersPhotos, link: "members-images" },
          { name: content.MyPhotos, link: "my-images" },
        ]}
      />
    </div>
  );
};

export default NavImages;
