import { useContextHook } from "../../Context/ContextOPen";
import {
  AiOutlineCloseCircle,
  PiWarningCircleBold,
} from "../../Context/exportIcons";
import { content } from "../../Context/translate";
import { Col } from "../../Tools/Grid-system";
import ButtonMain from "../Buttons/ButtonMain";
const MessagesSign = () => {
  const { messagesSign, setMessagesSign } = useContextHook();
  return (
    <div>
      {messagesSign && (
        <>
          <div
            onClick={() => {
              setMessagesSign(false);
            }}
            className="fixed top-0 left-0 w-full h-full z-[63] bg-black/30"
          />
          <Col
            sm={11}
            md={5}
            className="z-[65] bg-Secondary rounded-lg fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
          >
            <div>
              <div className="flex justify-between items-center text-white py-3 px-2 border-b">
                <div>
                  <AiOutlineCloseCircle
                    size={25}
                    className="hover:text-Main cursor-pointer hover:scale-105 transition-all"
                    onClick={() => {
                      setMessagesSign(false);
                    }}
                  />
                </div>
                <div className="text-white text-lg font-bold">
                  {content.Warning}
                </div>
                <div>
                  <PiWarningCircleBold
                    size={25}
                    className="hover:text-Main cursor-pointer hover:scale-105 transition-all"
                  />
                </div>
              </div>
              <div>
                <ul className="text-white py-2 px-3">
                  {[content.messageCheckAccount]?.map((e, i) => (
                    <li key={i} className="flex items-center py-1">
                      <div className="w-2 h-2 rounded-full bg-white mx-2"></div>
                      <div className="text-lg font-semibold">{e}</div>
                    </li>
                  ))}
                </ul>
                <ButtonMain
                  onClick={() => {
                    setMessagesSign(false);
                  }}
                  link="/auth/sign-in"
                  title={content.SignIn}
                  className="mx-3 my-2 bg-white !text-Secondary !text-lg !font-bold hover:bg-Main cursor-pointer hover:!text-white"
                />
                <ButtonMain
                  onClick={() => {
                    setMessagesSign(false);
                  }}
                  link="/auth/sign-up"
                  title={content.SignUp}
                  className="mx-3 my-2 bg-white !text-Secondary !text-lg !font-bold hover:bg-Main cursor-pointer hover:!text-white"
                />
              </div>
            </div>
          </Col>
        </>
      )}
    </div>
  );
};

export default MessagesSign;
