import { Link, NavLink, useLocation } from "react-router-dom";
import useAds from "../../Context/adsData";
import { avatars, ListSideBarUser } from "../../Context/lists";
import Help from "../../Svg/Help.svg";
import Help_2 from "../../Svg/Help2.svg";

import blog from "../../Svg/blog.svg";
import blog_2 from "../../Svg/blog2.svg";
import contactus from "../../Svg/contactus.svg";
import contactus_2 from "../../Svg/contactus2.svg";

import faq from "../../Svg/faq.svg";
import faq_2 from "../../Svg/faq 2.svg";
import {
  checkToken,
  fileUrl,
  gender,
  language,
  logout,
} from "../../Tools/APIs";
import { Col, Row } from "../../Tools/Grid-system";
import userDef from "../../images/icon-user-default.png";
import { content } from "../../Context/translate";
import { useContextHook } from "../../Context/ContextOPen";
import { ImageUser } from "../../components";
import { useEffect, useRef, useState } from "react";

const SideBarUser = ({ data }) => {
  const { setShowUserImages } = useContextHook();
  const { pathname } = useLocation();
  const selectedAvatar = avatars.filter(
    (e) => e.id === data?.data?.data?.avatar_id
  )[0];
  return (
    <div className="sticky top-[14vh]">
      {checkToken && (
        <div className="w-full rounded-2xl border bg-white overflow-hidden ">
          <div
            className={`${
              sessionStorage.getItem("gender") === "FEMALE"
                ? "bg-Main"
                : "bg-Secondary"
            } text-center text-white text-[16px] relative pb-2 `}
          >
            <div className="pt-8 pb-1  font-semibold text-3xl">
              {" "}
              {content.Welcome}{" "}
            </div>
            <div className=" font-semibold text-3xl">
              {data?.data?.data?.secret_data?.name}
            </div>
            <div className="w-fit absolute left-1/2 -translate-x-1/2 z-10 -bottom-[100px]">
              <ImageUser
                image={
                  data?.data?.data?.images &&
                  data?.data?.data?.images?.length !== 0
                    ? fileUrl +
                      data?.data?.data?.images[
                        data?.data?.data?.images.length - 1
                      ].image
                    : data?.data?.data?.avatar_id
                    ? selectedAvatar.img
                    : userDef
                }
                id={data?.data?.data?.login_details.id}
                isOnline={data?.data?.data?.isOnline}
                onClick={() =>
                  data?.data?.data?.images?.length !== 0 &&
                  setShowUserImages([data?.data?.data?.login_details.id, true])
                }
                className="w-20 h-20 rounded-full cursor-pointer"
              />
            </div>
          </div>
          <div
            className={`rounded-b-[90%] h-[70px] scale-110 w-full ${
              sessionStorage.getItem("gender") === "FEMALE"
                ? "bg-Main"
                : "bg-Secondary"
            }`}
          ></div>
          <div className="pb-7 pt-10 px-4">
            <ul className={` space-y-2 `}>
              {ListSideBarUser.map((e, i) => (
                <NavLink
                  key={i}
                  to={e.link}
                  className={`flex items-center gap-1 px-2 ${
                    sessionStorage.getItem("gender") === "FEMALE"
                      ? "hover:text-Secondary active-female"
                      : "hover:text-Main active-male"
                  } transition-all`}
                >
                  <e.icon size={23} />
                  <div className="flex items-center gap-4">
                    <li className=" pb-1 pt-2">{e?.name}</li>
                    {data?.data?.notifications_types &&
                      data?.data?.notifications_types[
                        e?.notification_name && e?.notification_name
                      ] > 0 && (
                        <p className="flex justify-center items-center bg-red-500 text-white rounded-lg px-1">
                          {
                            data?.data?.notifications_types[
                              e?.notification_name && e?.notification_name
                            ]
                          }
                        </p>
                      )}
                  </div>
                </NavLink>
              ))}
            </ul>
          </div>
        </div>
      )}
      <Ads posistion="profile" />
      <div className={`${pathname.includes("packages") ? "hidden" : ""}`}>
        <Row
          justify="between"
          className="bg-white !mt-2 w-full rounded-2xl border overflow-hidden "
        >
          <Col col={6} className="!m-0 hover:text-Main border-b p-2">
            <Link to="/help">
              <img
                src={gender === "FEMALE" ? Help_2 : Help}
                alt=""
                className="w-12 h-12 mx-auto"
              />
              <div className="text-center font-semibold py-2 ">
                {content.Help}
              </div>
            </Link>
          </Col>
          <Col
            col={6}
            className={`!m-0 hover:text-Main border-b p-2 ${
              language === "ar" ? "border-r" : "border-l"
            }`}
          >
            <Link to="/blog">
              <img
                src={gender === "FEMALE" ? blog_2 : blog}
                alt=""
                className="w-12 h-12 mx-auto"
              />
              <div className="text-center font-semibold py-2">
                {content.Blog}
              </div>
            </Link>
          </Col>
          <Col col={6} className="!m-0 hover:text-Main p-2">
            <Link to="contact-us">
              <img
                src={gender === "FEMALE" ? contactus_2 : contactus}
                alt=""
                className="w-12 h-12 mx-auto"
              />
              <div className="text-center font-semibold py-2 ">
                {content.ContactUs}
              </div>
            </Link>
          </Col>
          <Col
            col={6}
            className={`!m-0 hover:text-Main p-2 ${
              language === "ar" ? "border-r" : "border-l"
            }`}
          >
            <Link to="faqs">
              <img
                src={gender === "FEMALE" ? faq_2 : faq}
                alt=""
                className="w-12 h-12 mx-auto"
              />
              <div className="text-center font-semibold py-2">
                {content.FAQ}
              </div>
            </Link>
          </Col>
        </Row>
      </div>
      <Ads posistion="under_profile" />
    </div>
  );
};
export default SideBarUser;

export const Ads = ({ posistion, className }) => {
  const sectionRef = useRef(null);
  const [triggerFetch, setTriggerFetch] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setTriggerFetch(true);
          observer.disconnect(); // Stop observing once data is fetched
        }
      },
      {
        root: null, // Use the viewport as the container
        rootMargin: "0px",
        threshold: 0.1, // Trigger when 10% of the section is in view
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  const { adsData } = useAds(posistion, triggerFetch);

  return (
    <div ref={sectionRef}>
      {adsData?.type && (
        <div
          className={` border-2 border-Secondary rounded-2xl mx-auto my-10 shadow-md shadow-Main mb-10 ${
            className ? className : "max-h-[70vh]"
          } max-h-[70vh]`}
        >
          <a
            rel="noreferrer"
            href={adsData?.link}
            target="_blank"
            onClick={() => logout(`v1/ads/${adsData?.id}/click`)}
            className={`w-full rounded-2xl ${
              className ? className : "max-h-[70vh]"
            }`}
          >
            {adsData?.type === "IMAGE" ? (
              <img
                src={fileUrl + adsData?.file}
                alt=""
                className={`w-full rounded-2xl object-cover ${
                  className ? className : "max-h-[70vh]"
                }`}
              />
            ) : (
              <video className="object-contain" autoPlay loop muted>
                <source src={fileUrl + adsData?.file} />
              </video>
            )}
          </a>
        </div>
      )}
    </div>
  );
};
