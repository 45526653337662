import { useEffect, useState } from "react";
import { useContextHook } from "../../Context/ContextOPen";
import * as icons from "../../Context/exportIcons";
import { content } from "../../Context/translate";
import balloon from "../../images/ballon.png";
import { checkToken, language, ltrDirection } from "../../Tools/APIs";
import Heart from "../Heart/Heart";
import ImageUser from "../ImageUser/ImageUser";
import { useContextChat } from "../../Context/ContextChat";
import { Link } from "react-router-dom";

const MembersCard = ({
  isOnline,
  image,
  age,
  name,
  city,
  id,
  isCaring,
  status,
  user,
  country,
}) => {
  const { showMessagesSign, setShowBottomSheet, setMessageActivate } =
    useContextHook();

  const { isActive } = useContextChat();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [windowWidth]);

  return (
    <>
      <Link to={`/view-profile/${id}`} className="">
        <div
          style={ltrDirection}
          className="relative  bg-white  overflow-hidden rounded-xl shadow-sm shadow-gray-400   hover:scale-105 transition-all  "
        >
          <div>
            <div className="flex justify-center items-start p-4 cursor-pointer">
              <div
                className={`flex gap-2 items-center  ${
                  language === "ar" ? " flex-row-reverse" : ""
                }`}
              >
                <div>
                  <ImageUser
                    image={image}
                    id={id}
                    isOnline={isOnline}
                    imgspa={true}
                    className="w-[70px] h-[70px]"
                  />
                </div>
                <div className="flex-1 mt-2  ">
                  <div
                    className={`flex items-center gap-2 mx-1 justify-end ${
                      language === "ar" ? "" : "flex-row-reverse"
                    }`}
                  >
                    {/* <p className="bg-red-400 w-4 h-3 text-center"></p> */}
                    <p className="text-gray-600 break-all text-wrap md:text-base uppercase font-semibold lg:text-lg line-clamp-1 w-[80%] text-ellipsis max-md:text-lg text-start">
                      {name}
                    </p>
                  </div>
                  <div
                    className={`flex justify-start items-center mx-1 gap-1 !font-normal mt-1  ${
                      language === "ar" ? "flex-row-reverse" : ""
                    }`}
                  >
                    <p
                      className={` text-Secondary text-lg text-center lg:text-base md:text-sm  gap-2 max-md:text-base
                    `}
                    >
                      {age}
                    </p>
                    <p className="text-Secondary text-lg text-center lg:text-base  md:text-sm max-md:text-base">
                      {content.years}
                    </p>
                    <p className="text-Secondary text-lg text-center lg:text-base  md:text-sm max-md:text-base ">
                      {content.From + " " + country}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div
              className={`flex justify-between py-5 px-2 cursor-pointer    ${
                language === "ar" ? "flex-row-reverse" : ""
              }`}
            >
              <div
                className={`flex items-center gap-1 ${
                  language === "en" ? "flex-row-reverse" : ""
                }`}
              >
                <p
                  className={`font-light flex items-center max-md:text-sm text-ellipsis w-full line-clamp-1 ${
                    language === "ar" ? "flex-row-reverse" : ""
                  }`}
                >
                  {country + ` / ` + city}
                </p>
                <icons.IoLocationSharp color="#ff6589" fontSize={"1.5rem"} />
              </div>
              <div
                className={`flex items-center gap-1 ${
                  language === "ar" ? "flex-row-reverse" : ""
                }`}
              >
                <img src={balloon} alt="" className="w-6 h-6" />
                <p className="font-light text-base max-md:text-sm text-ellipsis w-full line-clamp-1">
                  {status}
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div
            className={`gap-3  p-3 mx-4 ${
              language === "ar" ? "flex justify-start" : "flex justify-end"
            }`}
          >
            {checkToken ? (
              <div
                onClick={() => {
                  if (!isActive) setMessageActivate(true);
                  else setShowBottomSheet([id, true]);
                }}
                className="flex items-center justify-center gap-1 p-3 rounded-full  cursor-pointer text-center bg-Secondary text-black font-semibold"
              >
                <icons.BiMessageRoundedDetail
                  size={25}
                  className="hover:scale-125 transition-all duration-200"
                  color="white"
                />
              </div>
            ) : (
              <div
                onClick={showMessagesSign}
                className="flex items-center justify-center gap-1 p-3 rounded-full  cursor-pointer text-center bg-Secondary text-black font-semibold"
              >
                <icons.BiMessageRoundedDetail
                  size={25}
                  className="hover:scale-125 transition-all duration-200"
                  color="white"
                />
              </div>
            )}
            <Heart
              user={user}
              id={user?.login_details?.id}
              check={isCaring ? isCaring : user?.is_caring}
              className="mr-3 bg-slate-200 p-3 rounded-full"
            />
          </div>
        </div>
      </Link>
    </>
  );
};

export default MembersCard;
