import React, { useEffect } from "react";
import { Checked } from ".";
import { useFETCH, usePOST } from "../../Tools/APIs";
import { Col } from "../../Tools/Grid-system";
import { ButtonMain, Cards } from "../../components";
import { content } from "../../Context/translate";
import Loading from "../../Tools/Loading";

const General = () => {
  const { data, isLoading } = useFETCH("v1/settings");
  const { handleSubmit, setFormData, loading, formData } = usePOST({});
  const handleSubmitMain = () => {
    handleSubmit("v1/settings/general/update?_method=PUT", "", "", true);
  };
  useEffect(() => {
    setFormData({
      display_online: data?.data?.data?.display_online,
    });
  }, [data?.data?.data]);

  if (isLoading || loading) return <Loading />;

  return (
    <div>
      <Col md={12} className="mx-auto pt-5">
        <Cards>
          <Checked
            active={formData?.display_online}
            onClick={() => {
              setFormData((prev) => ({
                ...prev,
                display_online: formData?.display_online ? 0 : 1,
              }));
            }}
            title={content.isOnline}
          />
          <div className="w-fit mr-auto">
            <ButtonMain
              title={content.Save}
              className="!px-14 mt-10"
              onClick={handleSubmitMain}
            />
          </div>
        </Cards>
      </Col>
    </div>
  );
};

export default General;
