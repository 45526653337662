import React, { useEffect } from "react";
import Discount from "../../images/discount.svg";
import ButtonMain from "../../components/Buttons/ButtonMain";
import { BiCheck, BiX } from "react-icons/bi";
import { checkToken, fileUrl, usePOST } from "../../Tools/APIs";
import { content } from "../../Context/translate";
import { FEATURES } from ".";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";

export default function PackageCard({ pack, isActive }) {
  const { handleSubmit, successfulPost, error, loading, response } = usePOST(
    {}
  );
  const navigate = useNavigate();
  const oldPrice = parseInt(pack.old_price);
  const newPrice = parseInt(pack.new_price);
  const discount = ((oldPrice - newPrice) / oldPrice) * 100;
  const duartion =
    parseInt(pack.duration) % 30
      ? `${pack.duration} ${content.day}`
      : `${parseInt(pack.duration) / 30} ${content.month}`;

  const submit = () => {
    if (checkToken) {
      handleSubmit(`v1/payment/stripe/payment-intent`, false, false, true, {
        package_id: pack.id,
      });
    } else {
      navigate("/auth/sign-in");
    }
  };

  useEffect(() => {
    if (error) {
      toast.dismiss("loading");
      toast.error(content.error);
    }
  }, [error]);

  useEffect(() => {
    if (successfulPost) {
      toast.dismiss("loading");
      navigate(`confirm?client_secret=${response.data.client_secret}`);
    }
  }, [successfulPost]);

  useEffect(() => {
    if (loading) {
      toast.loading(content.loading, { id: "loading" });
    }
  }, [loading]);

  return (
    <div className="flex h-full bg-gray-100 flex-col p-6 gap-4 rounded-2xl border-2 w-full">
      <img
        src={fileUrl + pack.image}
        className="w-20 h-20 mx-auto rounded-full"
      />
      <p className="text-2xl">{pack.name}</p>
      {!isActive && (
        <>
          {" "}
          <div className="flex gap-4  items-center">
            <span className="line-through text-Main font-thin">
              {oldPrice}$
            </span>
            <div className="flex gap-1 items-center">
              <img src={Discount} className="w-8 h-8" />
              <span className="text-2xl">{discount.toPrecision(3)}</span>
            </div>
          </div>
          <p>
            <span className="text-4xl">{newPrice}$</span>
            <span className="text-xl"> /{duartion}</span>
          </p>
          <ButtonMain onClick={submit} title={content.Subscribe} />
        </>
      )}
      <p>{content.features}</p>
      <ul className="">
        {FEATURES.map((feature) => (
          <li className="flex gap-2 items-center">
            {pack.features.split(",").includes(feature.key) ? (
              <BiCheck className="text-Main text-xl shrink-0" />
            ) : (
              <BiX className="text-Secondary text-xl shrink-0" />
            )}
            <span>{feature.name}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}
