import React from "react";

export default function LoadingPackageCard({ isActive }) {
  return (
    <div className="flex h-full bg-gray-100 flex-col p-6 gap-6 rounded-2xl border-2 w-full">
      <div className="w-20 h-20 mx-auto bg-gray-300 rounded-full"></div>
      <div className="h-6 bg-gray-300 w-3/4 mx-auto rounded"></div>
      {!isActive && (
        <>
          <div className="h-6 bg-gray-300 w-3/4 mx-auto rounded"></div>
          <div className="h-6 bg-gray-300 w-3/4 mx-auto rounded"></div>
          <div className="h-10 bg-gray-300 rounded w-3/4 mx-auto"></div>
        </>
      )}
      <div className="h-4 bg-gray-300 w-3/4 mx-auto rounded"></div>
      <div className="h-4 bg-gray-300 w-3/4 mx-auto rounded"></div>
      <div className="h-4 bg-gray-300 w-3/4 mx-auto rounded"></div>
      <div className="h-4 bg-gray-300 w-3/4 mx-auto rounded"></div>
    </div>
  );
}
