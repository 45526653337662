import { useEffect, useState } from "react";
import * as icons from "../../Context/exportIcons";
import { checkToken, useFETCH, usePOST } from "../../Tools/APIs";
import { useContextHook } from "../../Context/ContextOPen";
import { content } from "../../Context/translate";
import { useContextChat } from "../../Context/ContextChat";

function Heart({ id, className, size, check }) {
  const { isCaring, setIsCaring } = useContextChat();
  const { handleSubmit, successfulPost } = usePOST({});
  const [isCare, setIsCare] = useState(check);
  const { deleteCare, successfulDeleteCare } = useFETCH(
    "",
    `v1/care/${id}/delete`
  );
  const { setMessagesSuccess, showMessagesSign } = useContextHook();

  useEffect(() => {
    if (successfulPost) {
      setMessagesSuccess([content.MessageAddedFavorites]);
    }
  }, [successfulPost]);

  useEffect(() => {
    if (successfulDeleteCare) {
      setMessagesSuccess([content.MessageRemovedFavorites]);
    }
  }, [successfulDeleteCare]);

  return (
    <div
      className={`${className} ${
        isCare ? "bg-Main" : "bg-gray-400"
      } flex justify-center items-center`}
    >
      {isCare ? (
        <icons.FaHeart
          size={size ? size : 25}
          color="#c80000"
          className="cursor-pointer hover:scale-125 transition-all animate-pulse"
          onClick={() => {
            checkToken
              ? deleteCare(id).then((res) => {
                  setIsCare(false);
                })
              : showMessagesSign();
          }}
        />
      ) : (
        <icons.FaRegHeart
          size={size ? size : 25}
          className=" cursor-pointer text-[#c80000] hover:scale-125 transition-all duration-200"
          onClick={() => {
            checkToken
              ? handleSubmit("v1/care?cared_id=" + id, "", false, true).then(
                  (req) => {
                    setIsCare(true);
                  }
                )
              : showMessagesSign();
          }}
        />
      )}
    </div>
  );
}
export default Heart;
