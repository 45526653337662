import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Cards, Data, IconeCircle, ImageUser } from "../../components";
import { useContextHook } from "../../Context/ContextOPen";
import { IoMdSettings, MdModeEdit } from "../../Context/exportIcons";
import { content } from "../../Context/translate";
import userDef from "../../images/icon-user-default.png";
import bbb from "../../images/pngwing.com - 2023-10-04T201956 1.png";
import { fileUrl } from "../../Tools/APIs";
import { Col, Row } from "../../Tools/Grid-system";
import { avatars } from "../../Context/lists";
import Loading from "../../Tools/Loading";
import { useContextChat } from "../../Context/ContextChat";
import { BsFacebook, BsInstagram, BsTiktok, BsTwitter } from "react-icons/bs";
import { BiShare, BiShareAlt } from "react-icons/bi";
import ShareProfile from "../../components/PopUps/ShareProfile";

function HeadersProfile({ data, isLoading }) {
  const { loadingCounter } = useContextChat();
  const { pathname } = useLocation();
  const [showShare, setShowShare] = useState();
  const { setShowUserImages } = useContextHook();
  const selectedAvatar = avatars.filter((e) => e.id === data?.avatar_id)[0];
  const url = "http://" + window.location.host + "/#";

  useEffect(() => {
    sessionStorage.setItem("gender", data?.gender);
    sessionStorage.setItem(
      "country_id",
      data?.nationality_residence?.country?.id
    );
    sessionStorage.setItem(
      "employment_status_id",
      data?.study_work?.employment_status?.id
    );
    sessionStorage.setItem(
      "nationality_id",
      data?.nationality_residence?.nationality?.id
    );
  }, [data]);

  return (
    <>
      <div>
        {showShare && (
          <ShareProfile
            setShow={setShowShare}
            url={`${url}/view-profile/${data?.login_details.id}`}
          />
        )}
        <Cards className="!rounded-es-[50px] !rounded-se-[50px] !rounded-ee-[2px] !rounded-ss-[2px]">
          {isLoading && loadingCounter < 2 && <Loading />}
          <Row>
            <Col md={11} className="">
              <div className="relative">
                <div className="flex flex-col items-start gap-4 max-lg:flex max-lg:items-center justify-center">
                  <div className="flex justify-between items-center gap-4 w-full p-4">
                    <div className="flex gap-4 items-center">
                      <div>
                        <ImageUser
                          image={
                            data?.images && data?.images?.length > 0
                              ? fileUrl +
                                data?.images[data?.images.length - 1].image
                              : data?.avatar_id
                              ? selectedAvatar.img
                              : userDef
                          }
                          id={data?.login_details?.id}
                          isOnline={data?.isOnline}
                          onClick={() =>
                            data?.images?.length > 0 &&
                            setShowUserImages([data?.login_details?.id, true])
                          }
                          className="w-24 h-24 rounded-full cursor-pointer "
                        />
                      </div>
                      {data && (
                        <div className="flex flex-col gap-1">
                          <p className="text-3xl font-bold text-Main max-md:text-xl ">
                            {data?.secret_data?.name}
                          </p>
                          <p className="text-lg  font-semibold max-md:text-sm  ">
                            <span className="text-Secondary">
                              {data?.study_work?.employment_status?.name}
                            </span>
                          </p>
                          <p className="text-lg text-Secondary font-semibold max-md:text-sm  ">
                            <span className="f-number text-gray-400">
                              {data?.social_status?.age + content.years}
                              {data?.gender === "FEMALE"
                                ? content.residingInFemale
                                : content.residingInMale}
                            </span>
                            <span className="text-Forth">
                              {data?.nationality_residence?.country?.name +
                                " / "}{" "}
                              {data?.nationality_residence?.city?.name}{" "}
                            </span>
                          </p>
                        </div>
                      )}
                    </div>
                    <div className="flex sm:justify-between items-center gap-5 max-sm:flex-col max-sm:mt-3 max-sm:justify-center">
                      <IconeCircle Icone={MdModeEdit} link="/profile/edit" />
                      <IconeCircle
                        Icone={IoMdSettings}
                        link={`${
                          pathname === "/profile" ? "/settings/" : "settings/"
                        }`}
                      />{" "}
                      <BiShareAlt
                        onClick={() => setShowShare(true)}
                        size={25}
                        className="hover:scale-125 cursor-pointer transition-all hover:text-Main"
                      />
                    </div>
                  </div>
                  <Col lg={9} className="mx-auto">
                    <div className="border-b border-gray-400  lg:w-[55%] max-lg:w-[100%]"></div>
                  </Col>
                </div>
                <Row justify={"between"}>
                  <Col
                    md={6}
                    className="flex flex-col justify-center gap-4 !px-3"
                  >
                    <Data
                      text={data?.login_details?.username}
                      name={content.Username}
                      className="md:text-xl"
                    />
                    <Data
                      text={data?.login_details?.email}
                      name={content.Email}
                      className="md:text-xl"
                    />
                    <Data
                      text={data?.login_details?.id}
                      name={content.MembershipNumber}
                      className="md:text-xl"
                    />
                    <Data
                      text={data?.registered_since}
                      name={content.DateOfRegistration}
                      className="md:text-xl"
                    />
                    <div className="flex mt-8 gap-4  items-center">
                      {data?.instagram && (
                        <a href={data?.instagram} target="_blank">
                          <BsInstagram className="fill-Secondary" size={30} />
                        </a>
                      )}
                      {data?.twitter && (
                        <a href={data?.twitter} target="_blank">
                          <BsTwitter size={30} className="fill-Secondary" />
                        </a>
                      )}
                      {data?.facebook && (
                        <a href={data?.facebook} target="_blank">
                          <BsFacebook size={30} className="fill-Secondary" />
                        </a>
                      )}
                      {data?.tiktok && (
                        <a href={data?.tiktok} target="_blank">
                          <BsTiktok size={30} className="fill-Secondary" />
                        </a>
                      )}
                    </div>

                    {!data?.is_active && (
                      <p className="text-black font-semibold text-lg mt-3">
                        {content.ActivateAccount}{" "}
                        <Link
                          to="/account-activation"
                          className="text-Secondary font-bold underline"
                        >
                          {content.ClickHere}
                        </Link>
                      </p>
                    )}
                    <div className="md:hidden flex justify-center img relative max-md:justify-center">
                      <img
                        src={bbb}
                        alt=""
                        className="max-md:h-[200px] max-md:w-[300px] md:w-[100%] md:h-full object-contain "
                      />
                    </div>
                  </Col>
                  <Col
                    md={6}
                    className="flex justify-end img relative max-md:justify-center"
                  >
                    <img
                      src={bbb}
                      alt=""
                      className="max-md:h-[200px] max-md:w-[300px] md:w-[100%] md:h-full max-md:hidden object-contain "
                    />
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6} className="md:img max-md:img_mob "></Col>
          </Row>
        </Cards>
      </div>
    </>
  );
}
export default HeadersProfile;
