import React from "react";
import { Col } from "../../Tools/Grid-system";
import { ButtonMain, TitlePage } from "../../components";
import { useFETCH } from "../../Tools/APIs";
import LoadingPackageCard from "../../components/Cards/LoadingPackageCard";
import PackageCard from "../Packages/PackageCard";
import { content } from "../../Context/translate";
import { Link } from "react-router-dom";

export default function ActivePackages() {
  const { data, isLoading } = useFETCH(`v1/packages?active=1`);
  return (
    <Col md={11} className="mx-auto">
      <TitlePage title={"activePackages"} />
      <div className="grid w-full justify-center grid-cols-1 lg:grid-cols-2 gap-4">
        {isLoading ? (
          <>
            <LoadingPackageCard isActive />
            <LoadingPackageCard isActive />
          </>
        ) : data?.data.data.length > 0 ? (
          data?.data.data.map((pack) => <PackageCard isActive pack={pack} />)
        ) : (
          <div className="flex py-8 justify-center col-span-1 lg:col-span-2 items-center">
            <p className="text-gray-700 text-2xl font-bold">
              {content.noPackages}
            </p>
          </div>
        )}
      </div>
      <Link to="/packages">
        <ButtonMain
          className={"mt-12 w-fit mx-auto"}
          title={content.activate}
        />
      </Link>
    </Col>
  );
}
