import React from "react";
import { ButtonMain } from "../../components";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { toast } from "sonner";
import { content } from "../../Context/translate";

export default function ConfirmPayment() {
  const elements = useElements();
  const stripe = useStripe();

  const handlePayment = async () => {
    if (!stripe || !elements) {
      console.error("Stripe.js has not loaded.");
      return;
    }
    const { error: confirmationError, paymentIntent } =
      await stripe.confirmPayment({
        elements,
        redirect: "if_required",
      });

    if (confirmationError) {
      toast.error(confirmationError.message);
    } else {
      toast.success(content.successfullPayment);
    }
  };

  return (
    <div className="space-y-16 mt-16 md:w-2/3 lg:w-1/2 p-4 sm:p-8 rounded-md border-2 mx-auto">
      <div dir="ltr">
        <PaymentElement
          options={{ layout: "tabs", fields: { billingDetails: "auto" } }}
        />
      </div>
      <ButtonMain title={"الدفع"} onClick={handlePayment} />
    </div>
  );
}
