import React, { createContext, useContext, useState } from "react";
import { checkToken } from "../Tools/APIs";

const ContextOpen = createContext({});

const ContextProvider = ({ children }) => {
  const [listError, setListError] = useState([]);
  const [messagesSign, setMessagesSign] = useState(false);
  const [messagesSuccess, setMessagesSuccess] = useState("");
  const [messageActivate, setMessageActivate] = useState(false);
  const [openSideBar, setOpenSideBar] = useState(false);
  const [showUserImages, setShowUserImages] = useState(["", false]);
  const [showBottomSheet, setShowBottomSheet] = useState(["", false]);

  const showMessagesSign = () => {
    if (checkToken) return setMessagesSign(false);
    else return setMessagesSign(true);
  };
  return (
    <ContextOpen.Provider
      value={{
        openSideBar,
        setOpenSideBar,
        listError,
        setListError,
        messagesSign,
        setMessagesSign,
        showMessagesSign,
        messagesSuccess,
        setMessagesSuccess,
        showUserImages,
        setShowUserImages,
        showBottomSheet,
        setShowBottomSheet,
        messageActivate,
        setMessageActivate,
      }}>
      {children}
    </ContextOpen.Provider>
  );
};

export default ContextProvider;

export const useContextHook = () => {
  return useContext(ContextOpen);
};
