import { Link, NavLink } from "react-router-dom";
import Switch from "react-switch";
import { UserList } from "..";
import { BsBell } from "../../Context/exportIcons";
import { ListNavBar } from "../../Context/lists";
import { changeLanguage, content } from "../../Context/translate";
import { checkToken, language, ltrDirection } from "../../Tools/APIs";
import { ButtonMain } from "../../components";
import Logo from "../../images/qiranbook6 (1) 1.svg";

import SideBar from "./SideBar";
const NavBar = ({ data }) => {
  return (
    <div
      className={`sticky top-0 select-none left-0 w-full ${
        checkToken
          ? sessionStorage.getItem("gender") === "FEMALE"
            ? language === "ar"
              ? "from-Main to-[#ffb9c2]"
              : "from-[#ffb9c2] to-Main"
            : language === "ar"
            ? "from-Secondary to-[#daa4e5]"
            : "from-[#daa4e5] to-Secondary"
          : "bg-white"
      } bg-gradient-to-r py-1 px-5 shadow-md z-[100] max-md:h-[9vh] flex items-center`}
    >
      <div className="flex justify-between md:justify-around items-center w-full">
        <SideBar data={data} />
        <div>
          <Link to="/">
            <img src={Logo} alt="" width={75} />
          </Link>
        </div>
        <ul className="flex gap-x-10 font-semibold nav items-center max-md:hidden">
          {ListNavBar.map((e, i) => (
            <NavLink
              to={e.link}
              key={i}
              className={`text-center ${
                checkToken
                  ? sessionStorage.getItem("gender") === "FEMALE"
                    ? "hover:text-Secondary active-female text-white"
                    : "hover:text-Main active-male text-white"
                  : "hover:text-Main text-black"
              }
            transition-all duration-300 `}
            >
              <e.icon size={25} className="mx-auto" />
              <div className="max-lg:text-xs">{e.name}</div>
            </NavLink>
          ))}
        </ul>
        <div className="flex items-center gap-5">
          {checkToken ? (
            <div className="flex items-center gap-3">
              <NavLink
                to="/profile/notifications"
                className={`${
                  checkToken
                    ? sessionStorage.getItem("gender") === "FEMALE"
                      ? "hover:text-Secondary active-female text-white"
                      : "hover:text-Main active-male text-white"
                    : "hover:text-Main text-black"
                } transition-all duration-300 `}
              >
                <div className="relative">
                  {data?.data?.notifications_count > 0 && (
                    <span
                      className={`w-6 h-6 bg-red-600 text-white absolute -top-3 ${
                        language === "ar" ? "-left-3" : "-right-3"
                      }  rounded-full text-xs flex justify-center items-center`}
                    >
                      {data?.data?.notifications_count > 99
                        ? "+99"
                        : data?.data?.notifications_count}
                    </span>
                  )}
                  <BsBell size={25} className="cursor-pointer" />
                </div>
              </NavLink>
              <div className="mx-2">
                <UserList data={data?.data} />
              </div>
            </div>
          ) : (
            <>
              <div className="md:hidden">
                <ButtonMain
                  link="/auth/sign-in"
                  title={content.Enter}
                  className="border-Secondary hover:text-white hover:bg-Secondary max-lg:text-sm text-center"
                />
              </div>
              <div className="flex items-center gap-3 max-md:hidden">
                <div>
                  <ButtonMain
                    link="/auth/sign-in"
                    title={content.SignIn}
                    className="border-Main hover:text-white hover:bg-Main max-lg:text-sm text-center ml-2 "
                  />
                </div>
                <div>
                  <ButtonMain
                    link="/auth/sign-up"
                    title={content.SignUp}
                    className="hover:bg-Secondary border-Secondary hover:text-white max-lg:text-sm text-center"
                  />
                </div>
              </div>
            </>
          )}
          {/* <label
            style={ltrDirection}
            className="flex items-center gap-1 max-md:hidden">
            <div
              className={`${
                checkToken ? "text-white" : "text-black"
              } font-bold  `}>
              عربي
            </div>
            <Switch
              height={15}
              uncheckedIcon
              checkedIcon
              offColor="#cab8b8"
              onColor="#cab8b8"
              offHandleColor={
                checkToken
                  ? sessionStorage.getItem("gender") === "FEMALE"
                    ? "#008dc9"
                    : "#ff6584"
                  : "#ff6584"
              }
              onHandleColor={
                checkToken
                  ? sessionStorage.getItem("gender") === "FEMALE"
                    ? "#008dc9"
                    : "#ff6584"
                  : "#ff6584"
              }
              activeBoxShadow={`0 0 1px 1px ${
                checkToken
                  ? sessionStorage.getItem("gender") === "FEMALE"
                    ? "#008dc9"
                    : "#ff6584"
                  : "#ff6584"
              }`}
              handleDiameter={22}
              width={35}
              onChange={(e) => {
                if (e) changeLanguage("en");
                else changeLanguage("ar");
              }}
              checked={language === "en" ? true : false}
            />
            <div
              className={`${
                checkToken ? "text-white" : "text-black"
              } font-bold`}>
              EN
            </div>
          </label> */}
        </div>
      </div>
    </div>
  );
};

export default NavBar;
