import { useEffect } from "react";
import { useState } from "react";
import { useContextHook } from "../../Context/ContextOPen";
import { usePOST } from "../../Tools/APIs/index";
import { content } from "../../Context/translate";
import OTPInput from "react-otp-input";

import { Col, Container, Row } from "../../Tools/Grid-system/index";
import { ButtonMain, Cards } from "../../components";
import { useNext } from "../Auth/SignUp/Page2";
import Loading from "../../Tools/Loading";

function ActivateAccount() {
  const [code, setCode] = useState();
  const { setListError, setMessagesSuccess } = useContextHook();

  const { handleSubmit: handleSubmitGetCode, loading: loadingCode } = usePOST(
    {}
  );

  const { handleSubmit, formData, loading, successfulPost, setFormData } =
    usePOST({
      verification_code: "",
    });

  const { error } = useNext(formData);

  useEffect(() => {
    handleSubmitGetCode("v1/send/verification-code", false, false, true);
  }, []);

  useEffect(() => {
    if (successfulPost) {
      setMessagesSuccess([content.AccountActivation]);
    }
  }, [successfulPost]);

  useEffect(() => {
    setFormData({
      verification_code: code,
    });
  }, [code]);

  return (
    <Container>
      <Row>
        <Col md={8} className="mx-auto">
          <Cards className="mt-10">
            {(loading || loadingCode) && <Loading />}
            <Col md={10} className="mx-auto text-center">
              <h1 className="font-bold text-4xl max-sm:font-semibold max-sm:text-xl text-Main mb-4">
                {content.VerificationCode}
              </h1>
              <div className="flex justify-center flex-col items-center h-full text-center w-2/3 mx-auto ">
                <div>
                  <p className="text-Secondary">
                    {content.WeHaveSentTheVerificationCodeToYourEmail}
                  </p>
                  <p className="font-semibold">
                    {sessionStorage.getItem("email") &&
                      sessionStorage.getItem("email")}
                  </p>
                </div>
                <div
                  className="flex justify-center items-start gap-3 my-6"
                  style={{ direction: "ltr" }}
                >
                  <OTPInput
                    value={code}
                    onChange={setCode}
                    numInputs={4}
                    inputStyle="!w-10 border border-black rounded-md h-12 w-12 mx-2 px-3 out !outline-[#2684FF]"
                    renderSeparator={<span></span>}
                    renderInput={(props) => <input {...props} />}
                  />
                </div>
              </div>
              <ResetCode successfulPost={successfulPost} />
              <div className="w-full mt-4">
                <ButtonMain
                  className="w-[50%] p-2 mx-auto"
                  onClick={() => {
                    if (error.length > 0) {
                      return setListError([...error]);
                    }
                    handleSubmit("v1/check/verification-code", true, "/");
                  }}
                  title={content.Check}
                />
              </div>
            </Col>
          </Cards>
        </Col>
      </Row>
    </Container>
  );
}
export default ActivateAccount;

const ResetCode = () => {
  const { handleSubmit, successfulPost, loading } = usePOST({});
  const [remainingTime, setRemainingTime] = useState(60);
  useEffect(() => {
    const timer = setTimeout(() => {
      setRemainingTime((prevTime) => prevTime - 1);
    }, 1000);
    if (remainingTime === 0) {
      clearTimeout(timer);
    }
    return () => clearTimeout(timer);
  }, [remainingTime]);
  useEffect(() => {
    if (successfulPost) {
      setRemainingTime(60);
    }
  }, [successfulPost]);
  return (
    <div className="text-center max-sm:text-sm">
      {loading ? <Loading /> : ""}
      {remainingTime ? (
        <time>{"00:" + remainingTime} </time>
      ) : (
        <div
          className="cursor-pointer my-2 text-Secondary hover:underline text-lg"
          onClick={() =>
            handleSubmit("v1/send/verification-code", "", "", true)
          }
        >
          {content.ResendTheCode}
        </div>
      )}
    </div>
  );
};
