import { useEffect, useState } from "react";
import { useContextChat } from "../../Context/ContextChat";
import { useContextHook } from "../../Context/ContextOPen";
import * as icons from "../../Context/exportIcons";
import { ageVoid, childrenVoid } from "../../Context/functions";
import { avatars } from "../../Context/lists";
import { content } from "../../Context/translate";
import { checkToken, fileUrl, language, useFETCH } from "../../Tools/APIs";
import { Col, Row } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading";
import { Ads } from "../../layout/SideBarUser/SideBarUser";
import { BiShareAlt } from "react-icons/bi";
import PopUpNoUser from "../../components/PopUps/PopUpNoUser";
import Forbidden from "../../components/PopUps/Forbidden";
import { DataCol, Heart, ImageOff, ImageUser } from "../../components";
import { useNavigate, useParams } from "react-router-dom";
import ShareProfile from "../../components/PopUps/ShareProfile";
import { toast } from "sonner";

export default function ViewProfile() {
  const { id } = useParams();
  const { showMessagesSign, setShowBottomSheet } = useContextHook();
  const [showShare, setShowShare] = useState();
  const { setIsIgnoring, setIsCaring, isActive } = useContextChat();
  const navigate = useNavigate();
  const { data, isLoading, error } = useFETCH(`v1/users/show/${id}`);
  const dataAll = data?.data?.data;

  useEffect(() => {
    setIsCaring(dataAll?.is_caring);
    setIsIgnoring(dataAll?.is_ignoring);
  }, [dataAll]);

  useEffect(() => {
    if (error?.errors.length > 0) {
      toast.warning(content.notAllowedToSee);
      navigate("/");
    }
  }, [error]);

  const { setShowUserImages, setMessageActivate } = useContextHook();

  const selectedAvatar = avatars.filter((e) => e.id === dataAll?.avatar_id)[0];

  return (
    <div>
      {showShare && (
        <ShareProfile setShow={setShowShare} url={window.location.href} />
      )}
      <div className="">
        <div className=" bg-white h-full p-3 rounded-xl">
          {isLoading && <Loading />}
          {dataAll && !isLoading ? (
            <>
              <div className="flex justify-between items-center max-md:flex-col max-md:gap-5 border-b pb-3 shadow-sm">
                <div className="flex items-center gap-2 mx-8">
                  <ImageUser
                    image={
                      dataAll?.images && dataAll?.images?.length !== 0
                        ? fileUrl +
                          dataAll?.images[dataAll?.images.length - 1].image
                        : dataAll?.avatar_id
                        ? selectedAvatar.img
                        : ""
                    }
                    id={dataAll?.login_details.id}
                    isOnline={dataAll?.isOnline}
                    onClick={() =>
                      dataAll?.images?.length !== 0 &&
                      setShowUserImages([dataAll?.login_details?.id, true])
                    }
                    className="w-16 h-16 rounded-full cursor-pointer"
                    imgspa={true}
                  />
                  <div>
                    <p className="font-semibold mb-1">
                      {dataAll?.secret_data?.name}
                    </p>
                    <p className="font-semibold text-Main">
                      {ageVoid(dataAll?.social_status?.age)}
                      {" - "}
                      {dataAll?.social_status?.social_status?.name}
                      {" - "}
                      {dataAll?.gender === "FEMALE"
                        ? content.residingInFemale
                        : content.residingInMale}{" "}
                      {dataAll?.nationality_residence?.country?.name}
                    </p>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <div className="rounded-full group cursor-pointer w-10 h-10 p-2 text-white bg-Main">
                    <BiShareAlt
                      onClick={() => setShowShare(true)}
                      className="group-hover:scale-110 transition-all w-full h-full"
                    />
                  </div>
                  <Forbidden id={id} />
                  <Heart
                    id={dataAll?.login_details.id}
                    check={dataAll?.is_caring}
                    className={`rounded-full w-10 h-10 hover:bg-Main`}
                  />
                  <ImageOff
                    id={id}
                    check={dataAll?.has_profile_pic_privilege}
                    className="hover:bg-Main rounded-full w-10 h-10 flex items-center justify-center text-white"
                  />

                  <div className="bg-gradient-to-l from-Secondary to-Main rounded-full w-10 h-10 flex items-center justify-center text-white">
                    {checkToken ? (
                      <button
                        onClick={() => {
                          if (!isActive) setMessageActivate(true);
                          else {
                            setShowBottomSheet([id, true]);
                          }
                        }}
                      >
                        <icons.BsChatText size={25} color="white" />
                      </button>
                    ) : (
                      <div onClick={() => showMessagesSign()}>
                        <icons.BsChatText size={25} color="white" />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <Row
                className="bg-Third !my-2 !mx-2 py-2 px-2 rounded-xl"
                justify="between"
              >
                <Col col={5}>
                  <DataCol
                    text={dataAll?.last_active_at}
                    name={content.LastLoginDate}
                  />
                </Col>
                <Col col={5}>
                  <DataCol
                    text={dataAll?.registered_since}
                    name={content.RegistrationDate}
                  />
                </Col>
              </Row>
              <div className="flex items-center gap-2 text-lg font-bold text-Main">
                <icons.IoLocationSharp size={25} />
                {content.HousingAndMaritalStatus}
              </div>
              <Row
                justify={"between"}
                gap={2}
                className="bg-Third !my-2 !mx-2 py-2 px-2 rounded-xl"
              >
                <Col col={5}>
                  <DataCol
                    text={dataAll?.nationality_residence?.nationality?.name}
                    name={content.Nationality}
                  />
                </Col>
                <Col col={5}>
                  <DataCol
                    text={dataAll?.nationality_residence?.city?.name}
                    name={content.City}
                  />
                </Col>
                <Col col={5}>
                  <DataCol
                    text={
                      <>
                        {ageVoid(dataAll?.social_status?.age) +
                          " — " +
                          dataAll?.social_status?.social_status?.name}
                        <br />
                        {childrenVoid(+dataAll?.social_status?.children)}
                      </>
                    }
                    name={content.FamilyStatus}
                  />
                </Col>
                <Col col={5}>
                  <DataCol
                    text={dataAll?.social_status?.marriage_status?.name}
                    name={content.TypeOfMarriage}
                  />
                </Col>
                <Col col={5}>
                  <DataCol
                    text={
                      dataAll?.religious_commitment?.religiosity_status?.name
                    }
                    name={content.Religious}
                  />
                </Col>
                <Col col={5}>
                  <DataCol
                    text={dataAll?.religious_commitment?.prayer_status?.name}
                    name={content.Prayer}
                  />
                </Col>
              </Row>
              <div className="flex items-center gap-2 text-lg font-bold text-Main">
                <icons.IoMan size={25} />
                {content.AppearanceAndHealth}
              </div>
              <Row
                className="bg-Third !my-2 !mx-2 py-2 px-2 rounded-xl"
                justify={"between"}
              >
                <Col col={5}>
                  <DataCol
                    text={dataAll?.my_specifications?.skin_color?.name}
                    name={content.SkinColor}
                  />
                </Col>
                <Col col={5}>
                  <DataCol
                    text={`${dataAll?.my_specifications?.weight} ${content.kg} ${dataAll?.my_specifications?.height} ${content.cm} `}
                    name={content.HeightAndWeight}
                  />
                </Col>
                <Col col={5}>
                  <DataCol
                    text={dataAll?.my_specifications?.physique_status?.name}
                    name={content.Physique}
                  />
                </Col>
                <Col col={5}>
                  {dataAll?.gender === "FEMALE" ? (
                    <DataCol
                      text={dataAll?.religious_commitment?.hijab_status?.name}
                      name={content.Hijab}
                    />
                  ) : (
                    <DataCol
                      text={dataAll?.religious_commitment?.beard_status?.name}
                      name={content.Beard}
                    />
                  )}
                </Col>
                <Col col={5}>
                  <DataCol
                    text={dataAll?.study_work?.health_status?.name}
                    name={content.HealthStatus}
                  />
                </Col>
                <Col col={5}>
                  <DataCol
                    text={dataAll?.religious_commitment?.smoking_status?.name}
                    name={content.Smoking}
                  />
                </Col>
              </Row>
              <div className="flex items-center gap-2 text-lg font-bold text-Main">
                <icons.BsSuitcaseLg size={25} />
                {content.EducationAndWork}
              </div>
              <Row
                className="bg-Third !my-2 !mx-2 py-2 px-2 rounded-xl"
                justify={"between"}
              >
                <Col col={5}>
                  <DataCol
                    text={dataAll?.study_work?.educational_level?.name}
                    name={content.Educational}
                  />
                </Col>
                <Col col={5}>
                  <DataCol
                    text={dataAll?.study_work?.employment_status?.name}
                    name={content.Employment}
                  />
                </Col>
                <Col col={5}>
                  <DataCol
                    text={dataAll?.study_work?.job?.name}
                    name={content.Job}
                  />
                </Col>
                <Col col={5}>
                  <DataCol
                    text={dataAll?.study_work?.monthly_income_range?.name}
                    name={content.MonthlyIncome}
                  />
                </Col>
                <Col col={5}>
                  <DataCol
                    text={dataAll?.study_work?.financial_status?.name}
                    name={content.FinancialStatus}
                  />
                </Col>
              </Row>
              <div className="flex items-center gap-2 text-lg font-bold text-Main">
                <icons.BsFillExclamationCircleFill size={25} />
                {content.MySpecifications}
              </div>
              <Row className="bg-Third !my-2 !mx-2 py-2 px-1 rounded-xl">
                <div>{dataAll?.own_props}</div>
              </Row>
              <div className="flex items-center gap-2 text-lg font-bold text-Main">
                <icons.BsFillExclamationCircleFill size={25} />
                {content.SpecificationsOfMyLifePartner}
              </div>
              <Row className="bg-Third !my-2 !mx-2 py-2 px-1 rounded-xl">
                <div>{dataAll?.future_wife_props}</div>
              </Row>
            </>
          ) : (
            !isLoading && <div>{dataAll}</div>
          )}
          {!isLoading && (
            <div className="">
              <Ads posistion="user_popup" className="h-[30vh]" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
